<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="12">
        <data-table
          :headers="headers"
          :items="items"
          :options="pagination"
          :total="serverItemsLength"
          :last-page="serverLastPage"
          :loading="bLocalLoading"
          :hide-btn-action-delete="items.length === 1"
          btn-action-path="pricelists"
          btn-action-item-key-id="id"
          hide-btn-action-view
          is-route-name
          @delete="deleteItem"
          @update:sort-by="onSortBy"
          @update:sort-desc="onSortDesc"
          @update:options="onPagination"
          @update:page="onPage"
        >
          <template v-slot:[`item.active`]="{ item }">
            <active-icon :active="item.active" />
          </template>

          <template v-slot:[`item.is_default`]="{ item }">
            <active-icon :active="item.is_default" />
          </template>

          <template #[`item.name`]="{ item }">
            <v-btn
              :to="{ name: 'pricelists.update', params: { id: item.id } }"
              text
              color="primary"
            >
              {{ item.name }}
            </v-btn>
          </template>

          <template #[`item.actions`]="{ item }">
            <module-actions :value="item.id" @delete="deleteItem">
              <template #append-items>
                <v-list-item
                  class="primary--text"
                  :to="{ name: 'pricelists.view', params: { id: item.id } }"
                >
                  <v-list-item-icon>
                    <icon-coins />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="$t('update.prices')" />
                  </v-list-item-content>
                </v-list-item>
              </template>
            </module-actions>
          </template>
        </data-table>
      </v-col>
    </v-row>
    <pricelists-form
      v-model="obItem"
      :open.sync="displayForm"
      :key="$route.params.id"
      :fix-default="items.length === 1"
    />

    <price-list-table-drawer
      :value="obItem"
      :open.sync="previewItem"
      :key="`view-${$route.params.id}`"
    />
  </v-col>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import PricelistsMixin from "@/modules/pricelists/mixins/PricelistsMixin";
import ActiveIcon from "@/components/common/ActiveIcon.vue";
import PricelistsForm from "@/modules/pricelists/components/Form.vue";
import { DataTableHeader } from "@/mixins/DataTableMixin";
import Utils from "@/services/Utils";
import { PriceList } from "@planetadeleste/vue-mc-gw";
import PriceListTableDrawer from "@/modules/pricelists/components/PriceListTableDrawer.vue";
import CustomerPreviewDialog from "@/modules/customers/components/CustomerPreviewDialog.vue";
import IconCoins from "@/components/icons/IconCoins.vue";

@Component({
  components: {
    IconCoins,
    CustomerPreviewDialog,
    PriceListTableDrawer,
    ActiveIcon,
    PricelistsForm,
  },
})
export default class PricelistsList extends Mixins(PricelistsMixin) {
  isList = true;

  onMounted() {
    const arHeaders: DataTableHeader[] = [
      {
        text: "default",
        value: "is_default",
        class: "mw-150",
        sortable: false,
        sortOrder: 11,
      },
    ];
    this.addDTHeaders(arHeaders);

    this.index();
  }

  onRegisterEvents() {
    this.addEvent("before.show.form", this.getCode);
  }

  getCode(obItem: PriceList) {
    if (obItem.id || obItem.code) {
      return;
    }

    if (this.items.length >= 1) {
      this.obItem.set("is_default", false);
    }

    Utils.nextCode("pricelist").then((response) => {
      this.obItem.set("code", response.data);
    });
  }

  get items() {
    return this.obCollection.getModelList();
  }
}
</script>
